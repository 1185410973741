<template>
    <div class="partner-page">
        <div class="lv-basic-card">
            <easy-title id="ourPartners" class="lv-mb-52">
                Partner Logo Collection
            </easy-title>
            <partner-list></partner-list>
            <easy-title id="signingCeremony" class="lv-mb-52">
                Signing Ceremony
            </easy-title>
            <div class="lv-images-wall-wrapper">
                <img class="lv-images-wall-img" src="@/assets/images/partner/image_signing01@2x.png">
                <img class="lv-images-wall-img" src="@/assets/images/partner/image_signing02@2x.png">
                <img class="lv-images-wall-img" src="@/assets/images/partner/image_signing03@2x.png">
                <img class="lv-images-wall-img" src="@/assets/images/partner/image_signing04@2x.png">
                <img class="lv-images-wall-img" src="@/assets/images/partner/image_signing05@2x.png">
                <img class="lv-images-wall-img" src="@/assets/images/partner/image_signing06@2x.png">
                <img class="lv-images-wall-img" src="@/assets/images/partner/image_signing07@2x.png">
                <img class="lv-images-wall-img" src="@/assets/images/partner/image_signing08@2x.png">

            </div>
        </div>

    </div>
</template>

<script>
    import partnerList from "@/components/partnerList/partnerList";
    export default {
        name: "partner",
        components: {
            "partnerList":partnerList
        },
        mounted() {
            this.$scrollToElement(`#${this.$route.query.id}`);
        }

    }
</script>

<style lang="scss" scoped>
.partner-page{
    padding-top: 80px;
}
</style>